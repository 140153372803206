import { Fragment } from "react";

interface CheckInProps {
  checkIn: () => void;
}

const CheckIn = ({ checkIn }: CheckInProps) => {
  return (
    <Fragment>
      <span className="verification-title">본인 인증</span>
      <span className="verification-description">
        카카오 로그인을 통해 본인인증을 하시면
        <br />
        비대면 체크인 및 객실키를 발급해 드립니다.
      </span>
      <img
        className="kakao-login-button"
        src="../../assets/images/kakao-login-button.png"
        alt=""
        onClick={checkIn}
      />
    </Fragment>
  );
};

export default CheckIn;
