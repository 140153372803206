import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MainLayout from "../components/layouts/MainLayout";
import CheckIn from "../components/views/CheckIn";
import { RootState } from "../stores/reducers";
import { useSearchParams } from "react-router-dom";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckInContainer = (props: Props) => {
  const navigation = useNavigate();
  const { bookingItem } = useSelector((state: RootState) => state.bookingList);
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const isTestMode = process.env.REACT_APP_ENV === "development" ? true : false;
  const { Kakao } = window as any;

  const loginWithKakao = () => {
    if (isTestMode) {
      navigation("/room");
      return;
    }

    Kakao.Auth.authorize({
      redirectUri: "https://hotel-de-foret.h2ohospitality.io/checkin",
    });
  };

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== "RR")
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  useEffect(() => {
    if (code) navigation("/room");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div {...props}>
      <MainLayout ContentBody={<CheckIn checkIn={loginWithKakao} />} />
    </div>
  );
};

export default CheckInContainer;
