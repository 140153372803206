import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MainLayout from "../components/layouts/MainLayout";
import Payment from "../components/views/Payment";
import ModalError from "../components/ModalError";
import * as kiccApi from "../api/kicc";
import { RootState } from "../stores/reducers";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import * as sanhaApi from "../api/sanha";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckOutPaymentContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem } = useSelector((state: RootState) => state.bookingList);
  const [totalAmount, setTotalAmount] = useState(
    Number(searchParams.get("totalAmount ")) || 0
  );
  const [isLoading, setIsLoading] = useState(false);

  const openModalError = () => {
    setIsOpenModalError(true);
    setIsError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
  };

  const registTransaction = async () => {
    try {
      const { data: registedTransaction } = await kiccApi.registTransaction({
        mallId: process.env.REACT_APP_KICC_MALL_ID,
        payMethodTypeCode: "11",
        currency: "00",
        amount: bookingItem.roomRate || 1000,
        clientTypeCode: "00",
        returnUrl: process.env.REACT_APP_KICC_CALLBACK_URL,
        deviceTypeCode: "mobile",
        shopOrderNo: bookingItem.rsvnNo,
        orderInfo: {
          goodsName: "추가 사용료",
        },
      });
      return registedTransaction;
    } catch (error) {
      throw error;
    }
  };

  const confirmCheckOutPayment = async () => {
    try {
      setIsLoading(true);
      //미결제 금액 확인
      const confirmCheckOutAmountResponse =
        await sanhaApi.confirmCheckOutAmount({
          folioNo: bookingItem.folioNo,
        });
      if (confirmCheckOutAmountResponse.code !== "0000") {
        setModalErrorMessage("퇴실 미결제 금액 확인에 실패 하였습니다.");
        throw new Error(
          `${confirmCheckOutAmountResponse.code}, ${confirmCheckOutAmountResponse.message}`
        );
      } else if (!confirmCheckOutAmountResponse.data.balanceAmount) {
        navigation(`/?rsvnNo=${bookingItem.rsvnNo}`); //첫화면으로 원복
      } else {
        setTotalAmount(confirmCheckOutAmountResponse.data.balanceAmount);
      }
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    confirmCheckOutPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={
          <Payment
            bookingItem={bookingItem}
            registTransactionAPI={registTransaction}
            amount={totalAmount}
            setModalErrorMessage={setModalErrorMessage}
            setModalErrorSubMessage={setModalErrorSubMessage}
            openModalError={openModalError}
            isError={isError}
            errorMessage={modalErrorSubMessage}
          />
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default CheckOutPaymentContainer;
