import React, { useCallback, useEffect } from "react";
import MainLayout from "../components/layouts/MainLayout";
import TermsOfUseList from "../components/views/TermsOfUseList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores/reducers";
import { useNavigate } from "react-router-dom";
import { bookingListAction } from "../stores/actions";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const TermsOfUseListContainer = (props: Props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { bookingItem } = useSelector((state: RootState) => state.bookingList);

  const handleCheckIn = useCallback(
    (policy: boolean) => {
      const privacyPolicy = policy ? "Y" : "N";
      dispatch(bookingListAction.setPrivacyPolicy(privacyPolicy));
      navigation("/checkin");
    },
    [dispatch, navigation]
  );

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== "RR")
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`);
  }, [bookingItem.rsvnNo, bookingItem.rsvnStatusCode, navigation]);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={<TermsOfUseList handleCheckIn={handleCheckIn} />}
      />
    </div>
  );
};

export default TermsOfUseListContainer;
