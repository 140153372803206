import React from "react";
import MainLayout from "../components/layouts/MainLayout";
import FrontInfo from "../components/views/FrontInfo";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const FrontInfoContainer = (props: Props) => {
  return (
    <div {...props}>
      <MainLayout ContentBody={<FrontInfo />} />
    </div>
  );
};

export default FrontInfoContainer;
