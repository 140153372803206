import { Fragment, useState } from "react";
import _ from "lodash";
import CheckInConfirm from "./CheckInConfirm";
import roomMap from "../../assets/images/room-map.png";

interface RoomSelectProps {
  roomList: string[];
  selectedRoom: any;
  changeRoom: (roomNo: string) => void;
  checkIn: () => void;
}

const RoomSelect = ({
  roomList,
  selectedRoom,
  changeRoom,
  checkIn,
}: RoomSelectProps) => {
  const [isOpenModalCheckInConfirm, setIsOpenModalCheckInConfirm] =
    useState(false);

  const openModalCheckInConfirm = () => {
    setIsOpenModalCheckInConfirm(true);
  };

  const closeModalCheckInConfirm = () => {
    setIsOpenModalCheckInConfirm(false);
  };

  return (
    <Fragment>
      <div className="room-main-title">객실 선택</div>
      <img className="room-map" src={roomMap} alt="" />
      {roomList.length ? (
        <div className="room-container">
          <ul className="room-list-container">
            {roomList.map((roomNo) => {
              return (
                <li
                  key={roomNo}
                  className={roomNo === selectedRoom ? "click-on" : ""}
                  onClick={() => changeRoom(roomNo)}
                >
                  {roomNo}
                </li>
              );
            })}
          </ul>

          <div className="mt-26">
            <button
              className="confirm-button"
              onClick={openModalCheckInConfirm}
              disabled={_.isEmpty(selectedRoom)}
            >
              체크인 하기
            </button>
          </div>
        </div>
      ) : (
        <div className="non-data">선택할 수 있는 객실이 없습니다.</div>
      )}
      <CheckInConfirm
        isOpen={isOpenModalCheckInConfirm}
        selectedRoom={selectedRoom}
        onClose={closeModalCheckInConfirm}
        checkIn={checkIn}
      />
    </Fragment>
  );
};

export default RoomSelect;
