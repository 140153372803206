import React, { Fragment, useState, Dispatch } from "react";
import moment from "moment";
import LocationService from "./LocationService";
import LocationServiceGuideIOS from "./LocationServiceGuideIOS";

interface InitialBookingProps {
  bookingItem: any;
  setModalErrorMessage: Dispatch<React.SetStateAction<string>>;
  setModalErrorSubMessage: Dispatch<React.SetStateAction<string>>;
  openModalError: () => void;
}

const InitialBooking = ({
  bookingItem,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
}: InitialBookingProps) => {
  const [isOpenModalLocationService, setIsOpenModalLocationService] =
    useState(false);
  const [
    isOpenModalLocationServiceGuideIOS,
    setIsOpenModalLocationServiceGuideIOS,
  ] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const isIPhone = () => {
    const iPhone = "iPhone";
    if (navigator.userAgent.indexOf(iPhone) < 0) return false;
    else return true;
  };

  const openModalLocationService = () => {
    setIsOpenModalLocationService(true);
  };

  const closeModalLocationService = () => {
    setIsOpenModalLocationService(false);
  };

  const openModalLocationServiceGuideIOS = () => {
    setIsOpenModalLocationServiceGuideIOS(true);
  };

  const closeModalLocationServiceGuideIOS = () => {
    setIsOpenModalLocationServiceGuideIOS(false);
    setIsOpenModalLocationService(true);
  };

  const validateCheckIn = (checkinDate: string) => {
    if (!isConfirm) return;

    const availableCheckinDate = `${checkinDate}T1500`;
    if (moment(availableCheckinDate) <= moment()) {
      if (isIPhone()) openModalLocationServiceGuideIOS();
      else openModalLocationService();
    } else {
      setModalErrorMessage("체크인을 할 수 없습니다.");
      setModalErrorSubMessage(
        "체크인은 입실 당일 오후 3시 이후부터 가능합니다."
      );
      openModalError();
    }
  };

  const periodFormatter = (arrvDate: string, deptDate: string) => {
    return `${moment(arrvDate).format("YYYY.MM.DD")} ~ ${moment(
      deptDate
    ).format("YYYY.MM.DD")}`;
  };

  return (
    <Fragment>
      <div className="initial-booking-welcome-message-container">
        <span className="initial-booking-welcome-message">
          환영합니다. 고객님,
          <br />
          체크인을 진행하겠습니다.
        </span>
      </div>
      {bookingItem &&
      bookingItem.rsvnNo &&
      bookingItem.rsvnStatusCode === "RR" ? (
        <div>
          <div className="initial-booking-card-container">
            <div className="hotel-img-container"></div>
            <span className="booking-period">
              {periodFormatter(bookingItem.arrvDate, bookingItem.deptDate)}
            </span>
            <span className="hotel-name">{bookingItem.propertyName}</span>
            <div className="user-info-container">
              <img
                className="user-icon"
                src="../../assets/images/profile.png"
                alt=""
              />
              <span className="user-name">{bookingItem.guestName}</span>
              <span className="booking-number">{bookingItem.rsvnNo}</span>
            </div>

            <div className="notice">
              <div>
                <p className="notice-title">투숙객 확인사항</p>
                <ul>
                  <li>
                    고객의 귀책사유로 발생한 분실, 손상, 신체적 상해에 대해
                    호텔은 책임부담하지 않습니다.
                  </li>
                  <li>
                    지나친 소음으로 다른 이용객에 피해가 될 경우 퇴실조치 될 수
                    있습니다.
                  </li>
                </ul>
              </div>
              <div>
                <p className="notice-title">픽업차량 운행시간</p>
                <ul>
                  <li>체크인 픽업: 15:00~21:00</li>
                  <li>체크아웃 픽업: 09:00~11:00</li>
                  <span>(12:00~13:00 점심시간 이용불가)</span>
                </ul>
              </div>

              <div>
                <p className="notice-title">프론트 운영시간</p>
                <ul>
                  <li>프론트데스크: 09:00~21:00</li>
                  <br />
                  <li>
                    21:00 이후 직원이 상주하지 않기 때문에 요청사항은 21:00
                    전까지 요청 부탁 드립니다.
                  </li>
                  <li>
                    추가 침구는 저녁에 일괄적으로 전화드린 후 객실로 가져다
                    드립니다.
                  </li>
                  <li>
                    조식은 별도 추가시 08:30까지 문앞으로 비대면 배달
                    해드립니다. (현재 주문불가)
                  </li>
                </ul>
              </div>

              <span className="vehicle-guide">
                차량등록은 프론트에서 등록 부탁 드립니다.
                <br /> ※체크아웃 전까지 등록하지 않는 차량은 주차요금이
                부과됩니다※
              </span>
            </div>

            <div className="notice-agree-wrap">
              <input
                type="checkbox"
                id="notice-agree"
                onChange={() => setIsConfirm((prev) => !prev)}
              />
              <label htmlFor="notice-agree">해당 내용을 확인했습니다.</label>
            </div>

            <div
              className={`check-in-button ${isConfirm ? "" : "disabled"}`}
              onClick={() => validateCheckIn(bookingItem.arrvDate)}
            >
              <span className="check-in-button-text">체크인</span>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="initial-booking-card-container">
            {/* <div className='hotel-img-container'></div> */}
            <span className="no-booking">예약을 찾을 수 없습니다.</span>
          </div>
        </Fragment>
      )}
      <LocationService
        isOpen={isOpenModalLocationService}
        onClose={closeModalLocationService}
      />
      <LocationServiceGuideIOS
        isOpen={isOpenModalLocationServiceGuideIOS}
        onClose={closeModalLocationServiceGuideIOS}
      />
    </Fragment>
  );
};

export default InitialBooking;
