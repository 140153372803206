import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";
import ModalError from "../ModalError";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface GeolocationError {
  code: number;
  message: string;
}

const ModalLocationService = ({ isOpen, onClose }: Props) => {
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");

  const navigation = useNavigate();

  const verifyCurrentLocation = (): Promise<void> => {
    const hotelLat = 35.7882801;
    const hotelLng = 128.6352517;
    const centerRadiusOfHotel = 4000; // 4km
    const options: PositionOptions = {
      enableHighAccuracy: false,
      maximumAge: 5000,
      timeout: 3000,
    };

    return new Promise<void>((resolve, reject) => {
      if (!navigator.geolocation) {
        return reject({
          code: 0,
          message: "이 브라우저에서는 Geolocation이 지원되지 않습니다.",
        });
      }

      navigator.geolocation.getCurrentPosition(
        (pos: GeolocationPosition) => {
          const { latitude, longitude } = pos.coords;
          const currentDistance = getDistanceFromCurrentLocationToDestination(
            latitude,
            longitude,
            hotelLat,
            hotelLng
          );

          if (currentDistance < centerRadiusOfHotel) {
            resolve();
          } else {
            reject({
              code: 0,
              message: "현재 사용자 위치는 호텔 내부가 아닙니다.",
            });
          }
        },
        (error: GeolocationPositionError) => {
          const formatErrorMessage: { [key: number]: string } = {
            0: "알 수 없는 에러 입니다.\n 새로고침 후 다시 시도해 주세요.",
            1: "위치 서비스가 꺼져 있습니다. 위치 서비스를 켜 주세요.\n 새로고침 후 다시 시도해 주세요.",
            2: "위치를 찾을 수 없습니다.\n 새로고침 후 다시 시도해 주세요.",
            3: "요청 응답 시간이 초과 되었습니다. 재요청 해주세요.",
          };
          reject({
            code: error.code,
            message: formatErrorMessage[error.code] || error.message,
          });
        },
        options
      );
    });
  };

  const getDistanceFromCurrentLocationToDestination = (
    currentLat: number,
    currentLng: number,
    destinationLat: number,
    destinationLng: number
  ): number => {
    const R = 6371; // 지구의 반지름 (km)
    const dLat = toRadians(destinationLat - currentLat);
    const dLng = toRadians(destinationLng - currentLng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(currentLat)) *
        Math.cos(toRadians(destinationLat)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c * 1000; // 거리 (m)
  };

  const toRadians = (deg: number): number => {
    return deg * (Math.PI / 180);
  };

  const confirm = async () => {
    try {
      await verifyCurrentLocation();
      navigation("/terms_of_use");
    } catch (error) {
      const geolocationError = error as GeolocationError;
      setModalErrorMessage("체크인을 할 수 없습니다.");
      setModalErrorSubMessage(geolocationError.message);
      setIsOpenModalError(true);
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={
          <LocationServiceWrap>
            <span className="location-service-title">
              위치 정보 사용을 허용해 주세요
            </span>
            <div className="location-service-icon">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <span className="location-service-description">
              ※모바일 체크인은 호텔 반경 4㎞
              <br />
              이내에서 서비스가 활성화 됩니다.
            </span>
            <button className="confirm-button h-40 mb-14" onClick={confirm}>
              확인
            </button>
          </LocationServiceWrap>
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={() => setIsOpenModalError(false)}
      />
    </>
  );
};

export default ModalLocationService;

const LocationServiceWrap = styled.div`
  .location-service-title {
    display: block;
    height: 24px;
    margin: 14px 0 0;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
  }
  .location-service-icon {
    font-size: 90px;
    color: #5f5f5f;
    text-align: center;
  }
  @media (max-height: 420px) {
    .location-service-icon {
      font-size: 100px;
    }
  }
  @media (max-height: 370px) {
    .location-service-icon {
      font-size: 80px;
    }
  }
  .location-service-description {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    word-break: keep-all;
    color: #666;
  }
  @media (max-width: 320px) {
    .location-service-description {
      font-size: 13px;
    }
  }

  button.confirm-button {
    width: 100%;
    height: 48px;
    padding: 0;
    background-color: #333;
    border: unset;
    border-radius: 8px;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    color: #fff;
  }
  button.confirm-button:disabled {
    background-color: rgb(203, 204, 205);
  }
`;
