import React from "react";
import MainLayout from "../components/layouts/MainLayout";
import CheckOutPaymentSuccess from "../components/views/CheckOutPaymentSuccess";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckOutPaymentSuccessContainer = (props: Props) => {
  return (
    <div {...props}>
      <MainLayout ContentBody={<CheckOutPaymentSuccess />} />
    </div>
  );
};

export default CheckOutPaymentSuccessContainer;
