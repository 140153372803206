import { Fragment } from "react";
import Modal from "../Modal";
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface MobilekeyMenualProps {
  isOpen: boolean;
  onClose: () => void;
}

const KeyMenualWrap = styled.div`
  img.mobilekey-menual {
    width: 100%;
    height: 220px;
    object-fit: contain;
  }
  @media (max-height: 420px) {
    img.mobilekey-menual {
      height: 120px;
      margin: auto;
    }
  }
  .mobilekey-menual-title {
    display: block;
    height: 16px;
    margin: 0 auto 8px;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.4px;
    text-align: center;
    color: #999;
  }
  .mobilekey-menual-description {
    display: block;
    height: 30px;
    margin: 0 auto 4px;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: center;
    color: #000;
  }
  .mobilekey-setting-description {
    display: block;
    height: 20px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    color: #033fb2;
  }
  .app-link-container {
    display: flex;
    height: 36px;
    margin: 0 auto 22px;
    padding: 8px 14px 7px 15px;
    justify-content: center;
    font-size: 14px;
  }
  @media (max-width: 320px) {
    .mobilekey-menual-description {
      font-size: 18px;
    }
    .mobilekey-setting-description,
    .app-link-container {
      font-size: 12px;
    }
  }
  img.download {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  .app-link-container .app-name {
    display: block;
    height: 20px;
    margin: 0 2px 0 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #033fb2;
  }
  .app-link-container .download-label {
    display: block;
    height: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    color: #033fb2;
  }
  .slick-slider button.slick-arrow {
    display: none !important;
  }
  .slick-dots {
    display: flex !important;
    position: unset !important;
    bottom: unset !important;
    width: unset !important;
    padding: unset !important;
    margin: 0 0 4px !important;
    list-style: unset !important;
    text-align: unset !important;
    justify-content: center;
  }
  .slick-dots li {
    width: 5px !important;
    height: 5px !important;
    margin: 0 6px 0 !important;
  }
  .slick-dots li:first-child,
  .slick-dots li:last-child {
    margin: 0 !important;
  }
  .slick-dots li button {
    width: 5px !important;
    height: 5px !important;
    padding: 0px !important;
    background-color: #000 !important;
    opacity: 0.08 !important;
    border-radius: 20px !important;
  }
  .slick-dots li button::before {
    content: unset !important;
  }
  .slick-dots li.slick-active button {
    opacity: 0.8 !important;
  }
`;

const MobilekeyMenual = ({ isOpen, onClose }: MobilekeyMenualProps) => {
  const moveToApp = () => {
    const appLink = "https://ovalmms.page.link/key";
    window.open(appLink);
  };

  return (
    <KeyMenualWrap>
      <Modal
        isOpen={isOpen}
        isClosable={true}
        onClose={onClose}
        Content={
          <Fragment>
            <Slider dots={true} infinite={false} autoplaySpeed={400}>
              <div>
                <img
                  className="mobilekey-menual mb-20"
                  src="../assets/images/app-download-action.png"
                  alt=""
                />
                <span className="mobilekey-menual-title">
                  모바일 키 사용방법
                </span>
                <span className="mobilekey-menual-description">
                  모바일 키 앱 다운받기
                </span>
                <div className="app-link-container" onClick={moveToApp}>
                  <img
                    className="download mt-3 mr-6"
                    src="../assets/images/download.png"
                    alt=""
                  />
                  <span className="app-name">OVAL KEY</span>
                  <span className="download-label">다운받기</span>
                </div>
              </div>
              <div>
                <img
                  className="mobilekey-menual mb-20"
                  src="../assets/images/mobilekey-input-action.png"
                  alt=""
                />
                <span className="mobilekey-menual-title">
                  모바일 키 사용방법
                </span>
                <span className="mobilekey-menual-description h-60 mb-32">
                  모바일 키 4자리를
                  <br />
                  인증코드에 입력해주세요
                </span>
              </div>
              <div>
                <img
                  className="mobilekey-menual mb-20"
                  src="../assets/images/doorlock-touch-action.png"
                  alt=""
                />
                <span className="mobilekey-menual-title">
                  모바일 키 사용방법
                </span>
                <span className="mobilekey-menual-description h-60 mb-27">
                  객실 도어락 터치해서
                  <br />
                  도어락을 열어주세요
                </span>
              </div>
            </Slider>
          </Fragment>
        }
      />
    </KeyMenualWrap>
  );
};

export default MobilekeyMenual;
